.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: "PingFang SC", BlinkMacSystemFont, Roboto, "Helvetica Neue",
    sans-serifhtml, body;
  font-size: 16px;
}

.text-container {
  display: flex;
  height: 91vh;
  width: 100%;
}
/* 编辑器最多会被分成三份width:33.3%，当两份时根据flex-grow:1伸展 */
.text-box {
  width:33.3%;
  flex-grow:1;
  padding: 0 20px 20px 20px;
  word-wrap: break-word;
}

#marked-text {
  display: flex;
  justify-content: center;
}

.wx-box {
  overflow-y: auto;
  padding: 20px;
  width: 375px;
  height: 100%;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.06);
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
}
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.12);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.2);
}
