/*

    Name:       Base16 Default Light
    Author:     Chris Kempson (http://chriskempson.com)

    CodeMirror template by Jan T. Sott (https://github.com/idleberg/base16-codemirror)
    Original Base16 color scheme by Chris Kempson (https://github.com/chriskempson/base16)

*/

.cm-s-md-mirror.CodeMirror {
  background: #f5f5f5;
  color: #202020;
  font-size: 16px;
  padding: 20px;
  line-height: 25px;
}
.cm-s-md-mirror div.CodeMirror-selected {
  background: #e0e0e0;
}
.cm-s-md-mirror .CodeMirror-line::selection,
.cm-s-md-mirror .CodeMirror-line > span::selection,
.cm-s-md-mirror .CodeMirror-line > span > span::selection {
  background: #e0e0e0;
}
.cm-s-md-mirror .CodeMirror-line::-moz-selection,
.cm-s-md-mirror .CodeMirror-line > span::-moz-selection,
.cm-s-md-mirror .CodeMirror-line > span > span::-moz-selection {
  background: #e0e0e0;
}
.cm-s-md-mirror .CodeMirror-gutters {
  background: #f5f5f5;
  border-right: 0px;
}
.cm-s-md-mirror .CodeMirror-guttermarker {
  color: #ac4142;
}
.cm-s-md-mirror .CodeMirror-guttermarker-subtle {
  color: #b0b0b0;
}
.cm-s-md-mirror .CodeMirror-linenumber {
  color: #b0b0b0;
}
.cm-s-md-mirror .CodeMirror-cursor {
  border-left: 1px solid #505050;
}

.cm-s-md-mirror span.cm-comment {
  color: #ac002b;
}
.cm-s-md-mirror span.cm-atom {
  color: #aa759f;
}
.cm-s-md-mirror span.cm-number {
  color: #aa759f;
}

.cm-s-md-mirror span.cm-property,
.cm-s-md-mirror span.cm-attribute {
  color: #90a959;
}
.cm-s-md-mirror span.cm-keyword {
  color: #023a52;
}
.cm-s-md-mirror span.cm-string {
  color: #e46918;
}

.cm-s-md-mirror span.cm-variable {
  color: #90a959;
}
.cm-s-md-mirror span.cm-variable-2 {
  color: #00695f;
}
.cm-s-md-mirror span.cm-variable-3 {
  color: #2e6e8a;
}
.cm-s-md-mirror span.cm-def {
  color: #d28445;
}
.cm-s-md-mirror span.cm-bracket {
  color: #202020;
}
.cm-s-md-mirror span.cm-tag {
  color: #ac4142;
}
.cm-s-md-mirror span.cm-link {
  color: #b26a00;
}
.cm-s-md-mirror span.cm-error {
  /* background: #ac4142;
  color: #f5f5f5; */
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: #df8d8e;
}
.cm-s-md-mirror .CodeMirror-activeline-background {
  background: #dddcdc;
}
.cm-s-md-mirror .CodeMirror-matchingbracket {
  color: rgb(32,32,32) !important;
  background-color: rgba(0,0,0,0.1) !important;
}
